import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from './store';
import { Row, Col } from 'react-bootstrap';
import { Layout } from 'element-react';
import Menu from '../../common/menu';

class Environment extends PureComponent {

	constructor(props) {
		super(props);

		this.props.getEnvironmentData();

		if (this.props.location.search) {
			let str = this.props.location.search;
			str = parseInt(str.substring(1, str.length));
			this.props.handleItemClick(str);
		}

	}

	render () {
		const {
			list,
			activeIndex
		} = this.props;

		let newList = list.toJS();
		if (newList.length === 0) {
			return null;
		}
		let data = [];

		newList.forEach((item) => {
			data.push(item.name);
		})

		return (
			<Layout.Row type="flex" className="contentView">
	        	<Layout.Col span="1"></Layout.Col>
	        	<Layout.Col span="22">
				 <img src={require('../../static/proBanner.png')} alt='proBanner' className='proBanner'/>
				 <div className='content'>
		        	<Row>
		        		<Col xs={2} sm={2}>
							<Menu data={data}
								activeIndex={activeIndex}
								alt='environment'/>
		        		</Col>
		        		<Col xs={10} sm={10}>
		        			{
		        				(activeIndex !== 0) && <div className='contentArea'>
								<p className='prodTitle'>{data[activeIndex]}</p>
								<div className='sceneTitle resolveSceneTitle'>{newList[activeIndex].title}</div>
								{newList[activeIndex].desc && <p className='mg_top'>&nbsp;&nbsp;&nbsp;&nbsp;{newList[activeIndex].desc}</p>}
			        			<table className='prodTable'>
			        				<tbody>
				        				{activeIndex === 1 && <tr className='prodTableTitle'>
					        					<td>报告名称</td>
					        					<td>封装形式</td>
					        					<td>报告日期</td>
					        					<td>下载报告</td>
					        				</tr>
				        				}
				        				{
				        					activeIndex === 1 && newList[activeIndex].envList.map((item, index) => {
				        						return (
													<tr className='prodTableItem' key={item.id}>
							        					<td>{item.name}</td>
							        					<td>{item.form}</td>
							        					<td>{item.date}</td>
							        					<td><a className="sgsDownload" href={"https://www.ledsic.com.cn/" + item.link} target="_blank" download={item.link}>{item.download}</a></td>
							        				</tr>
				        						)
				        					})
				        				}
				        				{activeIndex === 2 && <tr className='prodTableTitle'>
					        					<td>物质名称</td>
					        					<td>禁用标准</td>
					        				</tr>
				        				}
				        				{
				        					activeIndex === 2 && newList[activeIndex].envList.map((item, index) => {
				        						return (
													<tr className='prodTableItem' key={item.id}>
							        					<td>{item.name}</td>
							        					<td>{item.desc}</td>
							        				</tr>
				        						)
				        					})
				        				}
			        				</tbody>
			        			</table>
								{
		        					activeIndex === 3 && <p className='envContent'>{newList[activeIndex].content}</p>
		        				}

		        			</div>
		        			}
		        		</Col>
		        	</Row>
		         </div>
	         </Layout.Col>
		       <Layout.Col span="1"></Layout.Col>
		    </Layout.Row>
		);
	}

}

const mapStateToProps = (state) => ({
    list: state.getIn(['environment', 'list']),
    activeIndex: state.getIn(['environment', 'activeIndex'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getEnvironmentData() {
            dispatch(actions.getEnvironmentInfo());
        },
        handleItemClick(index) {
			dispatch(actions.handleItemClick(index));
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Environment);